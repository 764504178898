import React from "react";
import logo from "../../assets/images/logo.svg";
import { maxWidth } from "../../constants";
import { StoreCard } from "../cards/storeCard";

export const NavBar = () => {
  return (
    <div
      className="
    px-4 py-6 shadow-md
    md:px-[70px] md:py-[24px] 
    bg-white lg:mt-0
    flex justify-center
    "
    >
      <div
        className={`w-full max-w-[${maxWidth}px] flex gap-2 items-center justify-between`}
      >
        <div>
          <img src={logo} alt="logo" className="max-w-[77px] md:max-w-fit" />
        </div>
        <div className="flex items-center gap-4">
          <div>
            <StoreCard type="playstore" className="" />
          </div>
          <div>
            <StoreCard type="appStore" className="" />
          </div>
        </div>
      </div>
    </div>
  );
};
