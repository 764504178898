import AOS from "aos";
import { gsap } from "gsap";
import React, { useEffect, useState } from "react";
import card from "../../assets/images/card-icon.svg";
import cards from "../../assets/images/cards.png";
import phone1 from "../../assets/images/phone1.png";
import phone2 from "../../assets/images/phone2.png";
import phone3 from "../../assets/images/phone3.png";
import phone4 from "../../assets/images/phone4.png";
import fixedSavings from "../../assets/images/savings.svg";
import savings from "../../assets/images/wallet-icon.svg";
import { InfoCard } from "../../components/cards/InfoCard";
import { CommentCard } from "../../components/cards/commentCard";
import { StoreCard } from "../../components/cards/storeCard";
import { Footer } from "../../components/footer";
import { NavBar } from "../../components/navbar";
import { maxWidth } from "../../constants";

const cardDetails = [
  {
    imgSrc: card,
    title: "Automated Savings",
    subtitle:
      "As you spend with your debit card(s), we help you save. Just specify the amount and we will save it for you.",
  },
  {
    imgSrc: savings,
    title: "Flexible Savings",
    subtitle:
      "Save, transfer, withdraw, manage and organise your money for free at any time.",
  },
  {
    imgSrc: fixedSavings,
    title: "Fixed Savings",
    subtitle:
      "Lock money away for a fixed duration until maturity. You can get a cash advance in time of need.",
  },
];

const comments = [
  "Get better returns on my money",
  "Invest in verified opportunities",
  "Save my money anytime I want",
];

export const LandingPage = () => {
  const [mouseMove, setMouseMove] = useState(null);

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  // GSAP animations
  useEffect(() => {
    gsap.to(["#cards"], {
      x:
        mouseMove?.pageX > mouseMove?.pageX / 2
          ? mouseMove?.pageX * 0.07
          : mouseMove?.pageX * 0.07 * -1,
      y:
        mouseMove?.pageY > mouseMove?.pageY / 2
          ? mouseMove?.pageY * 0.07
          : mouseMove?.pageY * 0.07 * -1,
      duration: 1,
    });
  }, [mouseMove]);

  return (
    <div className="flex flex-col h-full">
      <NavBar />

      {/* Page content  */}
      <div className="overflow-scroll h-full">
        <div className="">
          {/* Title section */}
          <div
            className="
            px-[24px] lg:px-[100px] 
            pt-[56px] md:pt-[104px] 
            flex flex-col 
            items-center justify-center 
            pb-[50px]
            
            "
            onMouseMove={(e) => setMouseMove(e)}
          >
            <div
              className="
                text-center w-full max-w-[906px]
                cabinet-grotesk
                font-black text-deep-primary
                text-[44px] md:text-[80px]
                leading-[118%] md:leading-[100%]
                "
              data-aos="fade-up"
              data-aos-duration={500}
            >
              SAVE WHEN YOU SPEND WITH YOUR CARD
            </div>

            <div
              className="
                text-center 
                text-[14px] md:text-[20px] 
                w-full max-w-[533px] mt-[32px]
                font-medium
                text-main-black/80
                "
              data-aos="fade-up"
              data-aos-duration={1000}
            >
              Save up to 2x faster. For every transaction you make with your
              debit cards, we help you save automatically.
              <br />
              <br />
              Simply put,{" "}
              <span className="text-primary font-bold">
                we help you save as you spend 😎
              </span>
            </div>

            <div
              className="max-w-[540px] mt-[64px]"
              data-aos="zoom-in"
              data-aos-duration={2000}
            >
              <img
                src={cards}
                alt="cards"
                id="cards"
                className="bounce max-w-[380px] md:max-w-[540px]"
              />
            </div>
          </div>

          {/* Ways and stuff section  */}
          <div
            className="
            bg-light-purple w-full 
            px-[24px] md:px-[70px] py-[48px] md:py-[120px]
            flex justify-center items-center
            "
          >
            <div
              className={`
            flex flex-col lg:flex-row w-full max-w-[${maxWidth}px]
            gap-[69px]
            items-center justify-between
            `}
            >
              <div className="w-full max-w-[405px] text-deep-primary">
                <div
                  className="
                text-[28px] md:text-[44px] font-bold leading-[127%]
                text-center lg:text-left
                "
                >
                  3 ways you can reach your savings target 2x faster
                </div>
                <div
                  className="
                mt-6 text-sm md:text-base
                text-center lg:text-left
                "
                >
                  Earn 5%-15% when you save with any of <br /> these MyCardNg
                  savings plans.
                </div>
              </div>

              <div className="flex-grow flex flex-wrap gap-6 justify-center md:justify-end">
                <div className="flex flex-col gap-6">
                  {cardDetails
                    ?.slice(0, 2)
                    ?.map(({ imgSrc, title, subtitle }, idx) => {
                      const key = `card-${idx}`;
                      return (
                        <InfoCard
                          title={title}
                          subtitle={subtitle}
                          imgSrc={imgSrc}
                          key={key}
                        />
                      );
                    })}
                </div>
                <div className="flex flex-col gap-6 justify-center">
                  {cardDetails
                    ?.slice(2)
                    ?.map(({ imgSrc, title, subtitle }, idx) => {
                      const key = `card-${idx}`;
                      return (
                        <InfoCard
                          title={title}
                          subtitle={subtitle}
                          imgSrc={imgSrc}
                          key={key}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          {/* Comments cards  */}
          <div className="w-full !overflow-hidden">
            <div
              className="
            py-[72px] w-max bg-white
            "
            >
              <div className="flex flex-row pl-[24px] gap-[24px] moving w-max relative">
                {comments?.map((text, idx) => {
                  const key = `cardlist1-${idx}`;
                  return <CommentCard text={text} key={key} />;
                })}
                <div className="absolute left-[100%] pl-[24px] flex flex-row gap-[24px] w-max">
                  {comments?.map((text, idx) => {
                    const key = `cardlist2-${idx}`;
                    return <CommentCard text={text} key={key} />;
                  })}
                  {comments?.map((text, idx) => {
                    const key = `cardlist3-${idx}`;
                    return <CommentCard text={text} key={key} />;
                  })}
                  {comments?.map((text, idx) => {
                    const key = `cardlist4-${idx}`;
                    return <CommentCard text={text} key={key} />;
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* Mockups */}
          <div
            className="
            bg-deep-primary w-full px-[24px] md:px-[70px]
            flex justify-center items-center
            py-[48px] md:py-[112px]
            "
          >
            <div className={`w-full max-w-[${maxWidth}px]`}>
              <div className="text-light-purple cabinet-grotesk text-[36px] md:text-[80px] font-black leading-[100%] max-w-[906px]">
                SAVING HAS NEVER BEEN EASIER THAN THIS{" "}
              </div>

              <div className="flex items-center gap-4 mt-[40px]">
                <div>
                  <StoreCard
                    type="playstore"
                    className="
                    !p-[16px] 
                    !rounded-[8px]
                    !text-sm
                    "
                  />
                </div>
                <div>
                  <StoreCard
                    type="appStore"
                    className="
                    !p-[16px] 
                    !rounded-[8px]
                    !text-sm
                    "
                  />
                </div>
              </div>

              <div className="flex flex-wrap flex-row justify-between gap-[20px] mt-[80px]">
                <img
                  src={phone1}
                  alt="phone1"
                  className="w-full max-w-[151px] md:max-w-[300px]"
                />
                <img
                  src={phone2}
                  alt="phone2"
                  className="w-full max-w-[151px] md:max-w-[300px]"
                />
                <img
                  src={phone3}
                  alt="phone3"
                  className="w-full max-w-[151px] md:max-w-[300px]"
                />
                <img
                  src={phone4}
                  alt="phone4"
                  className="w-full max-w-[151px] md:max-w-[300px]"
                />
              </div>
            </div>
          </div>

          {/* Download and revolutionalize  */}
          <div
            className="
          bg-white w-full px-[24px] md:px-[70px] pt-[72px] md:pt-[132px] pb-[102px]
          flex items-center
          "
          >
            <div
              className={`
            w-full max-w-[${maxWidth}px]
            flex items-center justify-between flex-wrap
            gap-8
            `}
            >
              <div
                className="text-[28px] md:text-[36px] text-deep-primary 
                font-bold w-full max-w-[525px]
                leading-[133%]
              "
              >
                Download MyCardNg today and revolutionize your financial journey
              </div>

              <div className="flex items-center gap-4 mt-[32px] md:mt-[40px]">
                <div>
                  <StoreCard
                    type="playstore"
                    className="!bg-deep-primary text-white
                    !p-[16px] 
                    !rounded-[8px]
                    !text-sm
                    "
                  />
                </div>
                <div>
                  <StoreCard
                    type="appStore"
                    className="!bg-deep-primary text-white
                    !p-[16px] 
                    !rounded-[8px]
                    !text-sm
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>

          {/* ------------------------------- */}
        </div>
      </div>
    </div>
  );
};
