import React from "react";
import logo from "../../assets/images/logo.svg";
import { maxWidth } from "../../constants";
import { Icon } from "../Icon/icon.js";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div className="px-[24px] md:px-[70px] py-[32px] bg-light-purple w-full flex items-center">
      <div className={`w-full max-w-[${maxWidth}px]`}>
        <div>
          <img
            src={logo}
            alt="logo"
            className="max-w-[133px] md:max-w-[95px]"
          />
        </div>

        <div
          className="
        mt-[24px] 
        flex flex-wrap gap-4 items-center justify-between 
        !font-normal text-deep-primary text-base
        "
        >
          <div className="font-normal">
            © 2022 MyCardNg. All rights reserved
          </div>
          <div>
            Contact us:{" "}
            <span className="!font-medium">support@mycardng.com</span>
          </div>
          <div>
            {/* <a href="/privacyPolicy"> Privacy Policy</a>
            <span className="!font-medium"></span> */}
       <Link to="/privacyPolicy">Privacy Policy</Link>
          </div>

          <div className="flex flex-row gap-2 items-center">
            <Icon svg="facebook" />
            <Icon svg="twitter" />
            <Icon svg="instagram" />
            <Icon svg="linkedIn" />
            <div>@MyCardNg</div>
          </div>
        </div>
      </div>
    </div>
  );
};
