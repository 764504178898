import React from "react";
import { LandingPage } from "./landingPage";
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';

import { PrivacyPolicy } from "./privacyPolicy";
export const Main = () => {
  return (
    <div className="w-full h-screen overflow-y-scroll satoshi text-main-black">
         <Router>
      <div>
 
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
    </div>
  );
};
