import React from "react";
import { ReactComponent as AppStore } from "../../assets/images/appStore.svg";
import { ReactComponent as Playstore } from "../../assets/images/playstore.svg";

export const StoreCard = ({ type, className }) => {
  return (
    <div
      className={`
    p-[8px] md:p-[16px] 
    rounded-[4px] md:rounded-[8px] flex gap-1
    text-xs md:text-sm
    items-center
    cursor-pointer
    hover:scale-95
    transition-all duration-300
    bg-light-purple
    ${className}
    `}
    >
      {type === "playstore" ? (
        <Playstore className="max-w-[16px] md:max-w-fit" />
      ) : (
        <AppStore className="max-w-[16px] md:max-w-fit" />
      )}

      <div className="">
        {type === "playstore" ? "Get on Android" : "Get on Apple"}
      </div>
    </div>
  );
};
