import AOS from "aos";
import { gsap } from "gsap";
import React, { useEffect, useState } from "react";
import card from "../../assets/images/card-icon.svg";
import cards from "../../assets/images/cards.png";
import phone1 from "../../assets/images/phone1.png";
import phone2 from "../../assets/images/phone2.png";
import phone3 from "../../assets/images/phone3.png";
import phone4 from "../../assets/images/phone4.png";
import fixedSavings from "../../assets/images/savings.svg";
import savings from "../../assets/images/wallet-icon.svg";
import { InfoCard } from "../../components/cards/InfoCard";
import { CommentCard } from "../../components/cards/commentCard";
import { StoreCard } from "../../components/cards/storeCard";
import { Footer } from "../../components/footer";
import { NavBar } from "../../components/navbar";
import { maxWidth } from "../../constants";

const cardDetails = [
  {
    imgSrc: card,
    title: "Automated Savings",
    subtitle:
      "As you spend with your debit card(s), we help you save. Just specify the amount and we will save it for you.",
  },
  {
    imgSrc: savings,
    title: "Flexible Savings",
    subtitle:
      "Save, transfer, withdraw, manage and organise your money for free at any time.",
  },
  {
    imgSrc: fixedSavings,
    title: "Fixed Savings",
    subtitle:
      "Lock money away for a fixed duration until maturity. You can get a cash advance in time of need.",
  },
];

const comments = [
  "Get better returns on my money",
  "Invest in verified opportunities",
  "Save my money anytime I want",
];

export const PrivacyPolicy = () => {
  const [mouseMove, setMouseMove] = useState(null);

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  // GSAP animations
  useEffect(() => {
    gsap.to(["#cards"], {
      x:
        mouseMove?.pageX > mouseMove?.pageX / 2
          ? mouseMove?.pageX * 0.07
          : mouseMove?.pageX * 0.07 * -1,
      y:
        mouseMove?.pageY > mouseMove?.pageY / 2
          ? mouseMove?.pageY * 0.07
          : mouseMove?.pageY * 0.07 * -1,
      duration: 1,
    });
  }, [mouseMove]);

  return (
    <div className="flex flex-col h-full">
      <NavBar />

      {/* Page content  */}
      <div className="overflow-scroll h-full">
        <div className="">
          {/* Title section */}
          <div
            className="
            px-[24px] lg:px-[100px] 
            pt-[56px] md:pt-[104px] 
            flex flex-col 
            items-center justify-center 
            pb-[50px]
            
            "
            onMouseMove={(e) => setMouseMove(e)}
          >
            <div
              className="
                text-center w-full max-w-[906px]
                cabinet-grotesk
                font-black text-deep-primary
                text-[44px] md:text-[80px]
                leading-[118%] md:leading-[100%]
                "
              data-aos="fade-up"
              data-aos-duration={500}
            >
             PRIVACY POLICY
            </div>

         
            <div
              className="
                text-center 
                text-[14px] md:text-[20px] 
                w-full  mt-[32px]
                font-medium
                text-main-black/80
                "
              data-aos="fade-up"
              data-aos-duration={1000}
              style={{ textAlign: 'left' }}
            >
           
           <section>
        <h2>A: Information We Collect:</h2>
        <ol>
            <li>
                Personal Information: When you access or use our services, we may collect personal information such as your name, address, contact details, financial information, and identification documents to verify your identity and fulfill legal requirements.
            </li>
            <li>
                Financial Information: We may collect financial information, including account details and transaction history, solely for the purpose of providing our services effectively and processing your transactions.
            </li>
            <li>
                Usage Information: We automatically collect certain information about your device, including your IP address, browsing activity, and geolocation data. This information helps us improve our services, troubleshoot any technical issues, and analyze trends.
            </li>
            <li>
                Cookies and Tracking Technologies: We may use cookies and similar technologies to enhance your experience on our website and mobile applications. These technologies allow us to remember your preferences and customize your interactions with our services.
            </li>
        </ol>
    </section>

    <section>
        <h2>B: How We Use Your Information:</h2>
        <ol>
            <li>
                Provide and Improve Our Services: We use your information to operate, maintain, and enhance our services, tailor them to your preferences, process your transactions, and improve their efficiency.
            </li>
            <li>
                Communication: We may use your contact information to send you notifications, updates, marketing materials, and respond to your inquiries or requests for support.
            </li>
            <li>
                Compliance: We may use your personal information to comply with legal obligations, enforce our terms and policies, prevent fraud, and protect our rights and interests.
            </li>
        </ol>
    </section>

    <section>
        <h2>C: How We Disclose Your Information:</h2>
        <ol>
            <li>
                Third-Party Service Providers: We may share your information with third-party service providers who assist us in delivering our services, maintaining our IT infrastructure, processing transactions, and managing communications.
            </li>
            <li>
                Legal and Compliance Obligations: We may disclose your information to comply with applicable laws, regulations, legal processes, or government requests.
            </li>
            <li>
                Business Transfers: In the event of a merger, acquisition, or sale of our assets, your information may be transferred as part of the transaction. We will notify you of any such event and any changes to our Privacy Policy.
            </li>
        </ol>
    </section>

    <section>
        <h2>D: Security Measures:</h2>
        <p>
            We are committed to maintaining the security of your personal information. We implement industry-standard security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.
        </p>
    </section>

    <section>
        <h2>E: Your Choices and Rights:</h2>
        <ol>
            <li>
                Opt-Out: You may choose to opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in our emails or contacting us directly.
            </li>
            <li>
                Access and Update: You have the right to access, update, or rectify your personal information. To do so, please contact us using the information provided below.
            </li>
            <li>
                Data Retention: We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
            </li>
        </ol>
    </section>

    <section>
        <h2>F: Changes to this Privacy Policy:</h2>
        <p>
            We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website. We encourage you to review this Privacy Policy periodically.
        </p>
    </section>

    <section>
        <h2>G: Contact Us:</h2>
        <p>
            If you have any questions, comments, or concerns regarding this Privacy Policy or our privacy practices, please contact us at:
        </p>
        <p>
            MyCardNG Privacy Team<br/>
            <a href="mailto:support@mycard.ng">support@mycard.ng</a>
        </p>
    </section>

              <br />
              <br />
           
            </div>

          </div>

          {/* Ways and stuff section  */}

          <div>
            <Footer />
          </div>

          {/* ------------------------------- */}
        </div>
      </div>
    </div>
  );
};
