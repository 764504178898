import "aos/dist/aos.css";
import { Main } from "./pages";
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

function App() {
  
  // return <Main />;
  ReactDOM.render(

      <Main />,
 
    document.getElementById('root')
  )
}

export default App;
