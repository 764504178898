import React from "react";

export const InfoCard = ({ title, subtitle, imgSrc }) => {
  return (
    <div
      className="
        w-full max-w-[400px] md:max-w-[300px]
        bg-white 
        text-deep-primary
        p-8 pb-10
        rounded-[24px]
     "
    >
      <div>
        <img src={imgSrc} alt="card" />
      </div>
      <div className="mt-6">
        <div className="text-base md:text-[20px] font-bold">{title}</div>
        <div className="mt-4 text-sm md:text-base min-h-[88px]">{subtitle}</div>
      </div>
    </div>
  );
};
