import React from "react";

export const CommentCard = ({ text }) => {
  return (
    <div
      className="
    min-h-[144px] md:min-h-[243px] w-max max-w-[400px]
    text-[24px] md:text-[42px]
    px-[32px] py-[16px] md:py-[24px]
    border border-primary
    rounded-[24px]
    font-bold
    text-primary
    "
    >
      “<br />
      <div>{text}</div>
    </div>
  );
};
